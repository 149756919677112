import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'gp-logout-prompt',
  standalone: true,
  imports: [ MatDialogModule, MatButtonModule ],
  templateUrl: './logout-prompt.component.html',
  styleUrls: ['./logout-prompt.component.scss']
})
export class LogoutPromptComponent {
  constructor(private ref: MatDialogRef<LogoutPromptComponent>) {}

  cancel() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(true);
  }
}
