<header class="gp-title-h5 gp-semi-bold">Business hour</header>
<ul>
  <li *ngFor="let workingDetail of businessHour" style="justify-content: space-between">
    <p>{{workingDetail.days}}:</p>
    <p>{{workingDetail.working}}</p>
  </li>
</ul>
<div class="license">
  <p>Full licensed by ministry of tourism</p>
  <p>Member of Association of Cyprus Travel Agents</p>
  <div class="logo">
    <img ngSrc="/assets/images/acta.png" [width]="85" [height]="85" alt="ACTA License"/>
    <img ngSrc="/assets/images/cyprus.png" [width]="42" [height]="74" alt="We Love Cyprus"/>
  </div>
</div>
