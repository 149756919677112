import { createAction, props } from '@ngrx/store';

export const resetPassword = createAction(
  '[Reset Password Page] Reset',
  props<{ email: string, newPassword: string, confirmNewPassword: string, verificationCode: string }>()
);

export const resetPasswordComplete = createAction(
  '[Reset Password API] Reset Complete'
);

export const resetPasswordFailed = createAction(
  '[Reset Password API] Reset Failed',
  props<{ error: unknown }>()
);
