import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@app/data/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { exhaustMap, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SnackbarComponent } from 'shared/components/snackbar/snackbar.component';
import { Injectable, isDevMode } from '@angular/core';
import { LoaderService } from 'core/services/loader.service';
import {
  changePassword,
  changePasswordFailed,
  changePasswordSuccess,
  fetchLoginDevices,
  fetchLoginDevicesSuccess,
  signOutAllDevices,
  signOutFromDevice, signOutFromDeviceFailure,
  signOutFromDeviceSuccess
} from '@app/data/store/auth/auth.actions';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  changePassword$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(changePassword),
        exhaustMap((payload) => {
          this.loader.setLoading(true);
          return this.authService.changePassword(payload.data)
            .pipe(
              map(() => ({type: changePasswordSuccess.type})),
              catchError((error) => of({type: changePasswordFailed.type, error}))
            );
        }));
  }, { useEffectsErrorHandler: false });

  changePasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePasswordSuccess),
      tap(() => {
        this.loader.setLoading(false);
        this.snackbar.openFromComponent(SnackbarComponent, {
          data: {
            message: $localize`Your password has been successfully updated`,
            status: 'success',
            showIcon: true
          }
        })
      })
    )
  }, {dispatch: false});

  changePasswordFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePasswordFailed),
      tap((error) => {
        let errorMessage = $localize`Change password has failed, please try again`;

        if ((error as unknown as any).error?.error?.error?.details[0]?.message) {
          errorMessage = (error as unknown as any).error?.error?.error?.details[0]?.message;
        }

        this.loader.setLoading(false);
        this.snackbar.openFromComponent(SnackbarComponent, {
          data: {
            message: errorMessage,
            status: 'error',
            showIcon: true
          }
        })
      })
    )
  }, {dispatch: false});

  fetchLoginDevices$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(fetchLoginDevices),
        exhaustMap(() => {
          this.loader.setLoading(true);
          return this.authService.getLoginDevices()
            .pipe(
              map((res) => {
                this.loader.setLoading(false);
                return { type: fetchLoginDevicesSuccess.type, data: res };
              }),
              catchError(() => {
                this.loader.setLoading(false);
                return of({ type: fetchLoginDevicesSuccess.type, data: [] });
              })
            );
        })
      );
  }, { useEffectsErrorHandler: false });

  logoutAllDevices$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(signOutAllDevices),
        exhaustMap(() =>
          (this.authService.signOutAllDevices()
            .pipe(
              tap(res => {
                console.log('SignOutAllDevicesRes', res);
                this.authService.clearToken();
                this.router.navigateByUrl('/')
              }),
              catchError(err => {
                console.error('SignOutAllDevicesError', err);
                return of(err);
              })
            )))
      )
  }, { dispatch: false });

  logoutOnDevice$ = createEffect(() => (this.actions$
      .pipe(
        ofType(signOutFromDevice),
        exhaustMap((payload) =>
          (this.authService.signOutDevice(payload.data.id)
            .pipe(
              tap(() => this.loader.setLoading(false)),
              map(() => ({ type: signOutFromDeviceSuccess.type, data: { id: payload.data.id } })),
              catchError(err => {
                if (isDevMode()) {
                  console.error('Error while trying to sign out on a device', err);
                }
                this.loader.setLoading(false);
                return of({ type: signOutFromDeviceFailure.type });
              })
            )))
      )
  ))

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private loader: LoaderService,
    private router: Router
  ) {}
}
