import { Component, Input, ViewEncapsulation, signal } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MaterialModule } from 'shared/material.module';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@app/data/services/auth.service';
import { logout } from '@app/views/auth/state/actions';
import { Store } from '@ngrx/store';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface MenuItem {
  icon: string;
  label: string;
  url: string;
  cb?: () => void;
}

@Component({
  selector: 'gp-profile-link',
  standalone: true,
  imports: [CommonModule, MaterialModule, MatMenuModule, RouterLink, NgOptimizedImage],
  templateUrl: './profile-link.component.html',
  styleUrls: ['./profile-link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileLinkComponent {
  @Input() username?: string;
  @Input() avatar?: string;

  userState$ = signal<boolean>(false);

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer
  ) {
    this.handleIconSvg();
  }

  notAuthorized: MenuItem[] = [
    {
      icon: 'enable',
      label: 'Log in',
      url: '/auth/login'
    },
    {
      icon: 'touch_app',
      label: 'Sign up',
      url: '/auth/register'
    },
  ];

  authorized: MenuItem[] = [
    {
      icon: 'person',
      label: 'Profile',
      url: '/profile'
    },
    {
      icon: 'bookmarks',
      label: 'History',
      url: '/booking'
    },
    {
      icon: 'logout',
      label: 'Logout',
      url: '/auth/logout',
      cb: this.onLogout.bind(this)
    }
  ];

  get menuItems(): MenuItem[] {
    if (this.authService.authenticated) {
      return this.authorized;
    } else {
      return this.notAuthorized;
    }
  }

  onLogout(): void {
    this.store.dispatch(logout());
  }

  onItemClick(item: MenuItem): void {
    if (item.cb) {
      item.cb();
    } else {
      this.router.navigateByUrl(item.url);
    }
  }

  private handleIconSvg(): void {
    this.matIconRegistry.addSvgIcon(
      'user',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'history',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/history.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'enable',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/enable.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logout',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/enable-logout.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'touch_app',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/touch_app.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'person',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/person.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bookmarks',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/bookmarks.svg')
    );
  }
}
