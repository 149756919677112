import { Injectable } from '@angular/core';
import { environment } from 'environments';
import { HttpService } from 'core/services/http.service';
import { CreateOrderApi, TourBookingApiResponse } from '@app/data/models/order/create-order';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApiResponse } from 'core/models/api.response';

@Injectable()
export class OrderService {
  private orderBaseUrl = `${environment.api.url}/tour-booking/order-management`;

  constructor(private http: HttpService) {
  }

  createOrder(data: CreateOrderApi): Observable<number> {
    return this.http.post<IApiResponse<TourBookingApiResponse>>(`${this.orderBaseUrl}/orders`, { data })
      .pipe(map(res => {
        if (res.data) {
          return res.data.id;
        } else {
          throw Error('Error while creating order');
        }
      }));
  }
}
