<footer>
  <section id="quick-links">
    <gp-link-column [links]="quickLinks" title="Quick Links" />
  </section>

  <section id="contact-info">
    <gp-link-column [links]="contactInfo" title="Contact Info" />
  </section>

  <section id="business-hour">
    <gp-footer-business-hour [businessHour]="businessHour" />
  </section>

  <section id="social-media">
    <gp-footer-social-media [socialMedia]="socialMedia" />
  </section>

  <section id="downloads">
    <header class="gp-title-h5 gp-semi-bold">Download our app</header>
    <div class="store-links">
      <gp-download-app [storeName]="'google-play'" />
      <gp-download-app [storeName]="'app-store'" />
    </div>
  </section>

  <section id="footer">
    <p class="copy gp-text-body2">{{copyRight}}</p>
  </section>
</footer>
