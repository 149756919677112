import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CityActions } from './city.actions';
import { CityService } from "../../services/city.service";

@Injectable()
export class CityEffects {

  loadCity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CityActions.loadCities),
      concatMap(() =>
        this.service.getCities().pipe(
          map(data => CityActions.loadCitiesSuccess({ data })),
          catchError(error => of(CityActions.loadCitiesFailure({ error }))))
      )
    );
  });


  constructor(
    private actions$: Actions,
    private service: CityService
  ) {
  }
}
