import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@app/data/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { register, registerComplete, registerFailed } from '@app/views/auth/state/actions';
import { exhaustMap, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SnackbarComponent } from 'shared/components/snackbar/snackbar.component';
import { Injectable } from '@angular/core';
import { LoaderService } from 'core/services/loader.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthRegisterEffects {
  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(register),
      exhaustMap((payload) => {
        this.loader.setLoading(true);
        return this.authService
          .register({
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            password: payload.password
          })
          .pipe(
            map(() => {
              return { type: registerComplete.type };
            }),
            catchError(() => of({ type: registerFailed.type, error: 'Registration failed' }))
          );
      }));
  });

  registerComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registerComplete),
      tap(() => {
        this.loader.setLoading(false);
        this.snackbar.openFromComponent(SnackbarComponent, {
          data: {
            message: $localize`Registration successfully done, you'll get redirect to login.`,
            status: 'success',
            showIcon: true
          }
        })
          .afterDismissed()
          .subscribe(() => this.router.navigateByUrl('/auth/login').then())
      })
    )
  }, {dispatch: false});

  registerFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registerFailed),
      tap(() => {
        this.loader.setLoading(false);
      })
    )
  }, {dispatch: false});

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private loader: LoaderService,
    private router: Router
  ) {}
}
