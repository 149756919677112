import { PriceApi } from '@app/data/models/shared/price';

export interface TourApi  {
  id: number;
  websiteThumbnailImageURL: string | null;
  applicationThumbnailImageURL: string | null;
  isFav: boolean;
  destination: string;
  duration: string;
  name: string;
  travelType: string;
  overview: string;
  contentShareUrl: string;
  price: PriceApi;
}
