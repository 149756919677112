import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'gp-back-to-top',
  standalone: true,
  imports: [
    MatIconModule
  ],
  templateUrl: './back-to-top.component.html',
  styleUrl: './back-to-top.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BackToTopComponent {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private _document: Document,
    private renderer: Renderer2,
  ) { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const elements = this._document.getElementsByClassName('back-to-top');
      const yOffSet = window.pageYOffset;
      const scrollTop = document.documentElement.scrollTop;
      const displayStyle = (yOffSet || scrollTop) > 100 ? 'block' : 'none';

      Array.from(elements).forEach((element: Element) => {
        this.renderer.setStyle(element, 'display', displayStyle);
      });
    }
  }

  //==================================
  // #region PUBLIC 
  //==================================
  scrollToTop(): void {
    ((): void => {
      const currentScroll =
        this._document.documentElement.scrollTop || this._document.body.scrollTop;
      if (currentScroll > 0) {
        if (isPlatformBrowser(this.platformId)) {
          window.requestAnimationFrame(() => this.scrollToTop());
          window.scrollTo(0, currentScroll - currentScroll / 8);
        }
      }
    })();
  }

  //#endregion
}
