// This one used as an interface to send data to backend
// The `tours/list` endpoint
export interface TourListParameters {
  query?: string;
  startDate?: string;
  timesOfDayType?: number[];
  cities?: number[] | null;
  interests?: number[];
  isTop?: boolean;
  pagingSize?: number;
  pagingIndex?: number;
  context?: string;
  categories?: number[] | null;
  sort?: string;
  timeOfDays?: number[];
  difficulties?: number[];
  durations?: number[];
  maxPrice?: number[];
  minPrice?: number[];
  departTime?: string;
}
