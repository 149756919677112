<div class="search-bar" [ngClass]="{'date': selectDate}">
  <gp-typing-identifier color="primary" [status]="inputStatus" />
  <input [(ngModel)]="value" [placeholder]="placeHolder"
         type="search" [matAutocomplete]="searchAuto" #searchInput>
  <mat-autocomplete #searchAuto="matAutocomplete">
    @for (suggestion of searchSuggestions$(); track suggestion.id) {
      @defer(prefetch on immediate) {
        <mat-option class="search-bar-auto-options" [value]="suggestion.title" (onSelectionChange)="onSuggestionSelect(suggestion)">
          <img [ngSrc]="suggestion.thumbnail" [alt]="'The ' + suggestion.title + ' image'" [fill]="true" />
          <div>
            <p class="gp-text-body1">{{suggestion.title}}</p>
            <span class="gp-text-body3">{{suggestion.subtitle}}</span>
          </div>
        </mat-option>
      }
    }
  </mat-autocomplete>
  <gp-date-picker *ngIf="selectDate; else clearInput" [(selectedDate)]="selectedDate"/>
  <ng-template #clearInput>
    <button (click)="onClear()" mat-icon-button style="" *ngIf="value && value.length > 0">
      <mat-icon [ngClass]="{'active': value && value.length > 0}">close</mat-icon>
    </button>
  </ng-template>
</div>
