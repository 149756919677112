import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  Input, model, OnDestroy, OnInit,
  signal,
  Signal,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {DatePickerComponent} from 'shared/components/search-bar/date-picker/date-picker.component';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {SearchSuggestion} from '@app/data/models/tour/search-suggestion';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TypingIdentifierComponent } from 'shared/components/typing-identifier/typing-identifier.component';
import { Actions, ofType } from '@ngrx/effects';
import { TourActions } from '@app/data/store/tour/tour.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectQuickSearchResponse } from '@app/data/store/tour/tour.selectors';

@Component({
  selector: 'gp-search-bar',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatCardModule, DatePickerComponent, FormsModule, MatButtonModule, MatAutocompleteModule, NgOptimizedImage, TypingIdentifierComponent],
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() value?: string;
  @Input() placeHolder = "Search your next adventure";
  @Input() selectDate?: boolean;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;

  searchSuggestions$: Signal<SearchSuggestion[] | undefined> = signal([]);
  selectedDate = model<Date>();

  inputStatus: 'idle' | 'typing' = 'idle';

  private destroyed$ = new Subject<void>();

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router
  ) {
    this.searchSuggestions$ = toSignal(this.store.select(selectQuickSearchResponse));
  }

  onInput(query: string): void {
    this.store.dispatch(TourActions.toursAPIQuickSearch({ data: { query, location: false } }))
  }

  onSuggestionSelect(selectedItem: SearchSuggestion): void {
    this.router.navigateByUrl(`tour-detail/${selectedItem.id}`)
  }

  ngOnInit(): void {
    this.listenToInputChange();
    this.listenToQuickSearchSuccess();
  }

  onClear(): void {
    this.value = undefined;
  }

  listenToInputChange(): void {
    fromEvent<Event>(this.searchInput.nativeElement, 'input')
      .pipe(
        tap(() => this.inputStatus = 'typing'),
        debounceTime(600),
        distinctUntilChanged(),
        map(event => (event.target as HTMLInputElement).value)
      )
      .subscribe(input => {
        this.onInput(input);
      });
  }

  private listenToQuickSearchSuccess(): void {
    this.actions$
      .pipe(
        takeUntil(this.destroyed$),
        ofType(TourActions.toursAPIQuickSearchSuccess)
      ).subscribe(() => this.inputStatus = 'idle');
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
