import { Component } from '@angular/core';
import { ThemeService } from 'core/services/theme.service';
import { LoaderService } from 'core/services/loader.service';

@Component({
  selector: 'gp-root',
  template: `
    <gp-header />
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="back-to-top">
    <gp-back-to-top />
    </div>
    <gp-footer />
    <ng-container *ngrxLet="loaderService.loading$ | async as isLoading">
      <gp-loader *ngIf="isLoading" />
    </ng-container>
  `,
  styles: [`
    .content {
      overflow: hidden;
      width: 100%;
      min-height: 100%;
      margin: 0 auto;
      padding-top: 64px;
    }
    gp-header {
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
    gp-footer {
      box-sizing: border-box;
    }
  `]
})
export class AppComponent {
  constructor(
    private themeService: ThemeService,
    public loaderService: LoaderService
  ) {
    this.themeService.setMode('light-mode');
  }
}
