import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from 'core/import.guard';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TourService } from '@app/data/services/tour.service';
import { tourFeature } from '@app/data/store/tour/tour.reducer';
import { TourEffects } from '@app/data/store/tour/tour.effects';

import { CityService } from '@app/data/services/city.service';
import { cityFeature } from '@app/data/store/city/city.reducer';
import { CityEffects } from '@app/data/store/city/city.effects';

import { AuthService } from '@app/data/services/auth.service';
import { UserEffects } from './store/user/user.effects';
import { userFeature } from '@app/data/store/user/user.reducer';
import { localStorageSyncReducer } from '@app/data/store/local-storage-meta-reducer';
import { AuthEffects } from '@app/data/store/auth/auth.effects';
import { authFeature } from '@app/data/store/auth/auth.reducers';
import { RecentSearchService } from '@app/data/services/recent-search.service';
import { OrderService } from '@app/data/services/order.service';

@NgModule({
  imports: [
    StoreModule.forFeature(tourFeature.name, tourFeature.reducer, {
      metaReducers: [localStorageSyncReducer]
    }),
    StoreModule.forFeature(cityFeature),
    StoreModule.forFeature(userFeature.name, userFeature.reducer, {
      metaReducers: [localStorageSyncReducer]
    }),
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([
      TourEffects,
      CityEffects,
      UserEffects,
      AuthEffects
    ])
  ],
  exports: []
})
export class DataModule {
  constructor(@Optional() @SkipSelf() parentModule: DataModule) {
    throwIfAlreadyLoaded(parentModule, 'DataModule');
  }

  static forRoot(): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        AuthService,
        CityService,
        TourService,
        RecentSearchService,
        OrderService
      ]
    };
  }
}
