import { Injectable, signal } from '@angular/core';
import { map, Observable, of, throwError } from 'rxjs';
import { environment } from "environments";

import {
  Tour,
  SortOption,
  TourListParameters,
  TourListApiResponse,
  TourDetailed, TourAvailability, mapTourGenericInfoToSearchFilter, TourDetailedAges
} from '../models';
import { IApiResponse } from 'core/models/api.response';
import { HttpService } from 'core/services/http.service';
import { catchError, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TourActions } from '@app/data/store/tour/tour.actions';
import {
  mapTourInfoApiToTourGenericInfo,
  TourGenericInfo,
  TourInfoApiResponse
} from '@app/data/models/tour/tour-info-api-response';
import { ITourSearchApiResponse } from '@app/data/models/tour/tour-quick-search-api-response';
import { TourDetailApiResponse } from '@app/data/models/tour/tour-detail-api-response';
import { CheckAvailability } from '@app/data/models/tour/check-availability';
import { CheckAvailabilityApiResponse } from '@app/data/models/tour/check-availability-api-response';
import { TourPriceDetails } from '@app/data/models/tour/tour-price-detail';

@Injectable()
export class TourService {
  #baseUrl = `${environment.api.url}/tour-booking/tour-management`;
  genericInfo$ = signal<Partial<TourGenericInfo>>({});
  ages$ = signal<TourDetailedAges[]>([]);

  constructor(
    private http: HttpService,
    private store: Store
  ) { }

  private generateCheckAvailabilityQuery(obj: CheckAvailability): string {
    const query: string[] = [];

    if (obj.tourId) {
      query.push(`TourId=${obj.tourId}`);
    }

    if (obj.startDate) {
      query.push(`StartDate=${obj.startDate}`);
    }

    if (obj.passengers) {
      for (const passenger of obj.passengers) {
        if (passenger.count > 0) {
          query.push(`TravelCount[${passenger.age.id}]=${passenger.count}`);
        }
      }
    }

    if (obj.lang) {
      query.push(`Language=${obj.lang}`);
    }

    return query.join('&');
  }

  getAvailableTours(searchParameters: CheckAvailability): Observable<{
    options: TourAvailability[],
    price: TourPriceDetails
  }> {
    return this.http
      .get<IApiResponse<CheckAvailabilityApiResponse>>(`${this.#baseUrl}/check-available?${this.generateCheckAvailabilityQuery(searchParameters)}`)
      .pipe(map(res => {
        if (res.data) {
          const mappedOptions = res.data.options.map(item => TourAvailability.fromJson(item));
          // Add the call option
          mappedOptions.push(TourAvailability.privateCallOption());
          return {
            options: mappedOptions,
            price: {
              breakDown: res.data.agesPrice,
              total: res.data.total
            }
          };
        }
        throw Error('The check availability response does not match', { cause: res });
      }));
  }

  private generateListQuery(obj: TourListParameters): string {
    const query: string[] = [];

    if (obj.query !== undefined)
      query.push(`Filter.Query=${obj.query}`);

    if (obj.startDate !== undefined)
      query.push(`StartDate=${obj.startDate}`);

    if (obj.timesOfDayType !== undefined) {
      obj.timesOfDayType.forEach(day => query.push(`TimeOfDayType=${day}`));
    }

    if (obj.cities) {
      obj.cities.forEach(city => { query.push(`Filter.Cities=${city}`) })
    }

    if (obj.categories) {
      obj.categories.forEach(category => query.push(`Filter.Categories=${category}`));
    }

    if (obj.interests !== undefined) {
      obj.interests.forEach(interest => query.push(`Interests=${interest}`));
    }

    if (obj.isTop !== undefined)
      query.push(`Filter.IsTopTour=${obj.isTop}`);

    if (obj.pagingSize !== undefined)
      query.push(`Paging.Size=${obj.pagingSize}`);

    if (obj.pagingIndex !== undefined)
      query.push(`Paging.Index=${obj.pagingIndex}`);

    if (obj.context !== undefined)
      query.push(`Context=${obj.context}`);

    if (obj.sort !== undefined)
      query.push(`${obj.sort}`);

    if (obj.timeOfDays) {
      obj.timeOfDays.forEach(day => query.push(`Filter.TimeOfDays=${day}`));
    }

    if (obj.difficulties) {
      obj.difficulties.forEach(difficulty => query.push(`Filter.Difficulties=${difficulty}`));
    }

    if (obj.durations) {
      obj.durations.forEach(duration => query.push(`Filter.Durations=${duration}`));
    }

    if (obj.maxPrice) {
      obj.maxPrice.forEach(price => query.push(`Filter.MaxPriceEUR=${price}`));
    }

    if (obj.minPrice) {
      obj.minPrice.forEach(price => query.push(`Filter.MinPriceEUR=${price}`));
    }

    if (obj.departTime) {
      query.push(`Filter.DepartDate=${obj.departTime}`);
    }

    if (query.length > 0)
      return query.join('&');
    else
      return '';
  }

  list(searchParameters: TourListParameters): Observable<Tour[]> {
    return this.http
      .get<IApiResponse<TourListApiResponse>>(`${this.#baseUrl}/list?${this.generateListQuery(searchParameters)}`)
      .pipe(
        tap(apiRes => {
          if (apiRes.data?.categories) {
            this.setCategories(apiRes.data.categories);
          }
          if (apiRes.data?.tours?.base) {
            this.setPaginationData(apiRes.data.tours.base);
          }
          return apiRes;
        }),
        map(res => {
          if (res.data?.tours?.items)
            return res.data.tours?.items.map(Tour.fromApi);
          else
            return [];
        }),
        catchError((err) => throwError(err))
      );
  }

  private setCategories(apiResponse: TourListApiResponse['categories']): void {
    this.store.dispatch(TourActions.setCategories({
      data: Object.entries(apiResponse).map(cat => ({ id: cat[0].toString(), label: cat[1] }))
    }));
  }

  private setPaginationData(apiResponse: TourListApiResponse['tours']['base']): void {
    this.store.dispatch(TourActions.setPagination({
      data: apiResponse
    }))
  }

  initGenericTourInfo(): void {
    this.http.get<IApiResponse<TourInfoApiResponse>>(`${this.#baseUrl}/information`)
      .pipe(take(1))
      .subscribe((res) => {
        if (res?.data) {
          this.genericInfo$.set(mapTourInfoApiToTourGenericInfo(res.data));
          this.store.dispatch(TourActions.toursAPIFetchFiltersSuccess({ data: mapTourGenericInfoToSearchFilter(this.genericInfo$()) }));
        }
      });
  }

  getSortOptions(): Observable<SortOption[]> {
    return of([
      {
        id: 'Orders[price]=0',
        label: 'Price (Low to High)'
      },
      {
        id: 'Orders[price]=1',
        label: 'Price (High to Low)'
      },
      {
        id: 'Orders[duration]=1',
        label: 'Duration (Short to Long)'
      },
      {
        id: 'Orders[duration]=0',
        label: 'Duration (Long to Short)'
      },
      {
        id: 'Orders[added]=1',
        label: 'Newly Added'
      }
    ]);
  }

  quickSearch(query: string, location: boolean): Observable<ITourSearchApiResponse> {
    return this.http.get<IApiResponse<ITourSearchApiResponse>>(`${this.#baseUrl}/quick-search?query=${query}&location=${location}`)
      .pipe(map(res => {
        if (res.data) {
          return res.data;
        } else {
          throw Error('Data was missing at the request response', { cause: res });
        }
      }));
  }

  getDetails(id: number): Observable<TourDetailed> {
    return this.http.get<IApiResponse<TourDetailApiResponse>>(`${this.#baseUrl}/details?Id=${id}`)
      .pipe(map(res => {
        if (res.data) {
          if (res.data.bookInforamtion.ages) {
            this.ages$.set(res.data.bookInforamtion.ages);
          }
          return TourDetailed.fromJson(res.data);
        } else {
          throw Error('Data is missing in response', { cause: res });
        }
      }));
  }
}
