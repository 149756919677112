import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { mergeMap, Observable, throwError } from 'rxjs';
import { AuthService } from '@app/data/services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(
      private authService: AuthService,
      private router: Router,
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !!this.authService.getRefreshToken()) {
          if (request.url.includes('refresh-token')) {
            this.authService.clearToken();
          } else {
            return this.handleRefreshToken(request, next);
          }
        } else if (error.status === 401) {
          this.authService.clearToken();
          this.router.navigateByUrl('/');
        }
        return throwError(() => error);
      })
    );
  }

  private handleRefreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.refreshAccessToken().pipe(
      mergeMap(newToken => {
        if (newToken) {
          request = request.clone({ setHeaders: { Authorization: `Bearer ${newToken}` } })
          return next.handle(request);
        } else {
          this.authService.clearToken();
        }
        return throwError(() => 'Unauthorized access token');
      }),
      catchError(err => throwError(() => `Error during refresh token. ${err}`))
    );
  }
}
