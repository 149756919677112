import { createAction, props } from '@ngrx/store';
import { ChangePasswordRequest } from '@app/data/models/auth/change-password-request';
import { IDevice } from '@app/data/models/auth/device';

export const changePassword = createAction(
  '[Reset Password API] Change',
  props<{ data: ChangePasswordRequest}>()
);

export const changePasswordSuccess = createAction(
  '[Reset Password API] Change Success'
);

export const changePasswordFailed = createAction(
  '[Reset Password API] Change Failed',
  props<{ error: unknown }>()
);

export const fetchLoginDevices = createAction(
  '[Login Devices] Fetch'
);

export const fetchLoginDevicesSuccess = createAction(
  '[Login Devices] Fetch Success',
  props<{ data: IDevice[] }>()
);

export const signOutFromDevice = createAction(
  '[Logout Device] Logout a Device',
  props<{ data: { id: string } }>()
);

export const signOutFromDeviceSuccess = createAction(
  '[Logout Device] Logout a Device Success',
  props<{ data: { id: string } }>()
);

export const signOutFromDeviceFailure = createAction(
  '[Logout Device] Logout a Device Failure'
);

export const signOutAllDevices = createAction(
  '[Logout Device] Logout All Devices'
);
