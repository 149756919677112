import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTours from './tour.reducer';
import { SearchSuggestion } from '@app/data/models/tour/search-suggestion';
import { ITourQuickSearchApiResponse } from '@app/data/models/tour/tour-quick-search-api-response';

export const selectTourState = createFeatureSelector<fromTours.TourState>(
  fromTours?.tourFeatureKey
);

export const selectTours = createSelector(
  selectTourState,
  (state) => state?.tours
);

export const selectSearch = createSelector(
  selectTourState,
  (state) => state?.search
);

export const selectFilters = createSelector(
  selectTourState,
  (state) => state?.search?.filters
);

export const selectSortOptions = createSelector(
  selectTourState,
  (state) => state?.search?.sortOptions
);

export const selectTourCategories = createSelector(
  selectTourState,
  (state) => state?.search?.tourCategories
);

export const selectAppliedFilters = createSelector(
  selectTourState,
  state => state?.search?.appliedSearchFilters
);

export const selectTotalTours = createSelector(
  selectTourState,
  state => (state?.search?.total)
);

export const selectQuickSearchResponse = createSelector(
  selectTourState,
  state => state?.search?.quickSearchResponse?.search.map(
    (tour: ITourQuickSearchApiResponse) => (<SearchSuggestion>{
      id: tour.id,
      title: tour.name,
      subtitle: tour.destination,
      thumbnail: tour.websiteThumbnailImageURL ? tour.websiteThumbnailImageURL : tour.applicationThumbnailImageURL
  }))
);

export const selectQuickSearchSuggestionResponse = createSelector(
  selectTourState,
  state => state.search.quickSearchResponse?.suggest.map(
    (tour: ITourQuickSearchApiResponse) => (<SearchSuggestion>{
      id: tour.id,
      title: tour.name,
      subtitle: tour.destination,
      thumbnail: tour.websiteThumbnailImageURL ? tour.websiteThumbnailImageURL : tour.applicationThumbnailImageURL
    }))
);

export const selectSelectedTourDetails = createSelector(
  selectTourState,
  state => state?.selectedTourDetails
);

export const selectTourSelectedCity = createSelector(
  selectTourState,
  state => state?.search?.selectedCity
)
