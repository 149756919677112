import {
  mapTourDifficultyEnum,
  mapTourDurationEnum,
  TourDetailApiResponse
} from '@app/data/models/tour/tour-detail-api-response';
import { AvailableLanguage } from '@app/data/models/tour/available-language';

export interface TourDetailedAges {
  id: number;
  title: string;
  from: number;
  to: number;
}

export class TourDetailed {
  id?: number;
  images: string[];
  title: string;
  location: string;
  duration: string;
  travelType: string;
  difficulty: string;
  overview: string;
  itinerary: { title: string; description: string; id?: number; step?: number; }[];
  includes: string;
  notIncludes: string;
  meetingPoint: { url: string; title: string; };
  cancellationPolicy: string;
  price: string;
  pricePrecision: number;
  currency: string;
  languages: AvailableLanguage[];
  maxNumPassengers: number;
  ages: TourDetailedAges[];

  static dummy(): TourDetailed {
    return {
      images: [
        'https://storage.googleapis.com/travel-web-app-1.appspot.com/flamelink/media/Flamingo%20lake.jpg?GoogleAccessId=firebase-adminsdk-g2s60%40travel-web-app-1.iam.gserviceaccount.com&Expires=16725225600&Signature=bGB0A1cDfTMoUFbZFUoEB7yrIuYGXm5c8b31QJspAuqRqC%2BrlahAXaG%2B73R%2BzIn7RJ91QrQyI8cWJ8sJgK%2FCDjm4tDNpjGqLIy4B8SREYU4p0kDtsKYSPPJqh9x5GQbvzk3zGxKbtX7A4r7AaRuVm5iOunReCNuS0YsHSYd0%2FHjyMFcyJEAyNmp%2FNrQ1VyQT72EbW6DlSLxk7EZNqS4Xo0BO7D1U9ukFc%2BZQnfeSoq0Nbz1t5X1OYdNhYWSjYm%2FXo8j6XOkF3iKVutq8dbpimHmnPY2hFJEBNJw6s2qWSzjLl7%2Fr59oICiyBsPhwNH3AO5aKtNR4AXiBrXFrLwRHKg%3D%3D',
        'https://storage.googleapis.com/travel-web-app-1.appspot.com/flamelink/media/flamingo-lake-isabela.jpg?GoogleAccessId=firebase-adminsdk-g2s60%40travel-web-app-1.iam.gserviceaccount.com&Expires=16725225600&Signature=WKy7N5iyIG77vyEbcwidw9dXwTK7kJ%2FAmtzrIiWEjevztZuF%2FYyYTSOWfFngaTYMQfVPYEdwE4wMMmvgpi7G8hrO4DniFERrxsszRXTfKTeb%2B7VYxYL%2Byfxoh1v4TCoESiMtyAr7FiGHYZmU%2BHx0EilFuXn3Mz8X4%2BVMobdGOVoFyoWDwYKbmtS3vqgiVwgRDDZ%2FnZvOqjWGpOyUC5pdQinDxZgkqPnqBIuihotSUUOJbVikCZqVplyUCPhYTMa4DQtbNQWfdserkEyGILJF27fM0KRnTASd%2FgpyFt9oIexapJrir%2FpGKrViFdg4dAOOZoWMPNxAC6R%2FOcZxlvdAkg%3D%3D',
        'https://thinkgalapagos.com/wp-content/uploads/2019/01/0001_22-Greater-Flamingo-Study.png',
        'https://www.happyingalapagos.com/wp-content/uploads/2014/02/galapagos-islands-a-flamingo-in-galapagos-islands-798x398.jpg',
        'https://i.pinimg.com/originals/79/5b/89/795b89b32bcace9fd449d58f9ffdad95.jpg',
        'https://www.galapatours.com/_next/image?url=https%3A%2F%2Fmedia.venturatravel.org%2Funsafe%2F1300x%2Fsmart%2Fgal%2Fanimal%2FFlamingo_89_of_240_.jpg&w=3840&q=75'
      ],
      title: 'Birding in Larnaca & Oroklini salt lake',
      location: 'Larnaca, Cyprus',
      duration: '4 - 8 hours',
      travelType: 'birdwatching',
      difficulty: 'easy',
      overview: `
Visit Madame Tussauds Las Vegas and journey down the Grand Canal on an outdoor gondola ride when you purchase this attraction package. Then dine at Buddy V&apos;s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City. Then dine at Buddy V&apos;s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City.

Fill text fill text
Visit the top attractions in Las Vegas at your leisure
Cruise the Grand Canal on an outdoor gondola ride
Receive admission to Madame Tussauds Las Vegas
Upgrade to enjoy lunch or dinner at Buddy V&apos;s Ristorante on the Trio Package
      `,
      itinerary: [
        {
          title: 'Title example',
          description: 'Fill text Then dine at Buddy V\'s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City. Then dine at Buddy V\'s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City.'
        },
        {
          title: 'Title example',
          description: 'Fill text Then dine at Buddy V\'s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City. Then dine at Buddy V\'s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City.'
        },
        {
          title: 'Title example',
          description: 'Fill text Then dine at Buddy V\'s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City. Then dine at Buddy V\'s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City.'
        },
        {
          title: 'Title example',
          description: 'Fill text Then dine at Buddy V\'s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City. Then dine at Buddy V\'s Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City.'
        }
      ],
      includes: `Lorem ipsuadipisicing elit. A consectetur cumque minus molestiae',
        'A consectetur cumque cupiditate, dignissimos minus molestiae',
        'Adipisicing elit. A consectetur cumque cupiditate, dignissimos minus molestiae',
        'Lorem ipsum dolor sit cupiditate, dignissimos minus molestiae',
      `,
      notIncludes: `Lorem ipsuadipisicing elit. A consectetur cumque minus molestiae <br />A consectetur cumque cupiditate, dignissimos minus molestiae
      Adipisicing elit. A consectetur cumque cupiditate, dignissimos minus molestiae
      Lorem ipsum dolor sit cupiditate, dignissimos minus molestiae`,
      meetingPoint: {
        title: 'Vasileos Georgiou A, Office 35 E, 4040 Potamos Germasogias, Limassol, Cyprus',
        url: 'https://www.google.com/maps/place/GP+Hermes+Travel+%26+Tours/@34.6930615,33.0814427,15z/data=!4m6!3m5!1s0x26b8587ea7c2c415:0xa26dbb9e63cdf57!8m2!3d34.6930615!4d33.0814427!16s%2Fg%2F11rz3vh3jz?entry=ttu'
      },
      cancellationPolicy: `
Visit Madame Tussauds Las Vegas and journey down the Grand Canal on an outdoor gondola ride when you purchase this attraction package. Then dine at Buddy V's Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City. Then dine at Buddy V's Ristorante located on the Venetian Hotel and Casino (if Trio Package selected). Choose to enjoy all the activities consecutively on one day, or spread them out over the course of your stay in Sin City.

Fill text fill text
Visit the top attractions in Las Vegas at your leisure
Cruise the Grand Canal on an outdoor gondola ride
Receive admission to Madame Tussauds Las Vegas
Upgrade to enjoy lunch or dinner at Buddy V's Ristorante on the Trio Package
      `,
      price: '60',
      pricePrecision: 2,
      currency: '€',
      languages: [
        { id: 1, label: 'English' },
        { id: 2, label: 'Persian' },
        { id: 3, label: 'French' },
      ],
      maxNumPassengers: 10,
      ages: []
    }
  }

  static fromJson(data: TourDetailApiResponse): TourDetailed {
    const tourDetail = new TourDetailed();

    tourDetail.id = data.id;
    tourDetail.images = data.gallery.map(p => p.url);
    tourDetail.title = data.headerInformations.name || 'MOCK - Tour Title';
    tourDetail.location = data.headerInformations.destination || 'MOCK - Destination';
    tourDetail.duration = mapTourDurationEnum(data.headerInformations.duration);
    tourDetail.travelType = data.headerInformations.travelType || 'MOCK - Travel Type';
    tourDetail.difficulty = mapTourDifficultyEnum(data.headerInformations.difficultyType);
    tourDetail.overview = data.headerInformations.overview || 'MOCK - Very interesting description about the tour';
    tourDetail.itinerary = data.itineraries.map(iti => ({ id: iti.step, title: iti.name || 'MOCK - Itinerary Name', description: iti.description || 'MOCK - Itinerary Description', step: iti.step }));
    tourDetail.includes = data.included.isIncluded || 'MOCK - What has been included';
    tourDetail.notIncludes = data.included.notIncluded || 'MOCK - What has not been included';
    tourDetail.meetingPoint = { title: data.meetingPoint.title || 'MOCK - Meting point Title', url: data.meetingPoint.meetingPointLink || 'https://maps.app.goo.gl/k9jA4WY7xQYzMEGb7' };
    tourDetail.cancellationPolicy = data.cancellationPolicy.desription || 'MOCK - Please call support team for cancellation policy';
    tourDetail.price = data.bookInforamtion.fromPrice.amountWithDiscount || data.bookInforamtion.fromPrice.amount || '0';
    tourDetail.pricePrecision = data.bookInforamtion.fromPrice.precision || 0;
    tourDetail.currency = data.bookInforamtion.fromPrice.currency || '$';
    tourDetail.languages = data.bookInforamtion.languages?.map(lang => ({
      id: lang.id,
      label: lang.title
    })) || [{ id: 0, label: 'MOCK - English' }];
    tourDetail.maxNumPassengers = data.bookInforamtion.maximumTravels;
    tourDetail.ages = data.bookInforamtion.ages;

    return tourDetail;
  }
}
