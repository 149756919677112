import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from '@app/data/services/auth.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
class AuthGuard {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    return of(this.authService.authenticated).pipe(
      map(storeOrApiAuth => {
        if (!storeOrApiAuth) {
          return this.router.createUrlTree([this.authService.authFailureUrl]);
        }
        return true;
      })
    );
  }
}

export const canActivateGuard: CanActivateFn = () => {
  return inject(AuthGuard).canActivate();
};
