
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
  imports: [
	MatCardModule,
	MatIconModule,
	MatButtonModule,
	MatExpansionModule,
  ],
  exports: [
	MatCardModule,
	MatIconModule,
	MatButtonModule,
	MatExpansionModule,
  ],
})
export class MaterialModule {
}
