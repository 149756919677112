<div class="section-breadcrumb">
    <div class="container-pv">
        <div class="breadcrumb-list">
            <a class="breadcrumb-item has-link" [routerLink]="'/'" i18n>{{HOME}}</a>
            <ng-container *ngFor="let item of breadcrumbs">
                <a class="breadcrumb-item has-link" *ngIf="item.url !== ''" [routerLink]="item.url"
                    i18n>{{item.label}}</a>
                <span class="breadcrumb-item" *ngIf="item.url === ''" i18n>{{item.label}}</span>
            </ng-container>
        </div>
        <!-- <div class="book-online" *ngIf="hasReservation">
            Book online or call 
            <a href="tel:0035725212566">+357 25212566</a>
        </div> -->
    </div>
</div>