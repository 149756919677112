import { Price } from '@app/data/models/shared/price';
import { DifficultyType, DurationType } from '@app/data/models/common.enums';

interface GalleryItem {
  type: number;
  url: string;
}

interface HeaderInformations {
  name: string;
  destination: string;
  duration: DurationType;
  travelType: string;
  difficultyType: DifficultyType;
  detailsApplication: string;
  overview: string;
  contentShareUrl: string;
}

interface Itinerary {
  step: number;
  name: string;
  description: string | null;
}

interface Included {
  isIncluded: string;
  notIncluded: string;
}

interface MeetingPoint {
  title: string;
  meetingPointAddress: string;
  meetingPointLink: string;
}

interface CancellationPolicy {
  title: string;
  desription: string;
}

interface Language {
  id: number;
  title: string;
}

interface Age {
  id: number;
  title: string;
  from: number;
  to: number;
}

interface BookInformation {
  maximumTravels: number;
  fromPrice: Price;
  languages: Language[];
  ages: Age[];
}

export interface TourDetailApiResponse {
  id: number;
  gallery: GalleryItem[];
  headerInformations: HeaderInformations;
  itineraries: Itinerary[];
  included: Included;
  meetingPoint: MeetingPoint;
  cancellationPolicy: CancellationPolicy;
  bookInforamtion: BookInformation;
}

export function mapTourDifficultyEnum(difficulty: DifficultyType): string {
  const difficulties = {
    0: 'None',
    1: 'Easy',
    2: 'Medium',
    3: 'Hard',
  };

  return difficulties[difficulty];
}

export function mapTourDurationEnum(duration: DurationType): string {
  const durations = {
    0: 'None',
    1: 'Up to an hour',
    2: 'One to 4 hours',
    3: 'Four hour to a Day',
    4: 'One to 3 days',
    5: 'Up 3 days',
  }

  return durations[duration];
}
