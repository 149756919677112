import { TravelType } from 'shared/components/product/travel-type/travel-type.component';
import { TourApi } from './tour-api';

/*function getDuration(type: TourDurationType): string {
  switch (type) {
    case TourDurationType.Day1:
      return '1 day';
    case TourDurationType.From2To4:
      return '2-4 hours';
    case TourDurationType.From4To8:
      return '4-8 hours';
    case TourDurationType.Up1Day:
      return '+1 day';
    default:
      return '3-4 hours';
  }
}*/

// TODO: Add currency to tour object
export class Tour {
  constructor(
    public image: string,
    public favorite: boolean,
    public location: string,
    public travelTime: string,
    public title: string,
    public travelType: TravelType,
    public description: string,
    public priceStart: number,
    public discounted: number,
    public currency: string,
    public precision: number,
    public id?: number | string,
  ) {
  }

  static fromApi(data: TourApi): Tour {
    return new Tour(
      (data.websiteThumbnailImageURL?.length ? data.websiteThumbnailImageURL : data.applicationThumbnailImageURL) || '',
      data.isFav,
      data.destination,
      data.duration,
      data.name,
      data.travelType as unknown as TravelType,
      data.destination,
      data.price.amount as unknown as number,
      data.price.amountWithDiscount as unknown as number,
      data.price.currency,
      data.price.precision,
      data.id
    );
  }

  static dummy(): Tour {
    return new Tour(
      'https://i.natgeofe.com/k/40a56f75-39ec-40d7-9b8e-da7fa289c066/flamingo-standing_2x3.jpg',
      false,
      'Libia, Cyprus',
      '4-8 hours',
      'Amazing Tour About Felamingos',
      'birdwatching',
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat, saepe tempore. Adipisci aliquid, at commodi consequuntur eaque eligendi enim ipsam laboriosam nisi odit omnis quibusdam repellendus repudiandae saepe sit veniam?',
      60,
      40,
      '$',
      2
    );
  }
}
