import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SocialLinks } from 'shared/layout/footer/footer.component';

@Component({
  selector: 'gp-footer-social-media',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './social-media.component.html',
  styleUrls: ['../footer.component.scss', './social-media.component.scss']
})
export class SocialMediaComponent {
  @Input() socialMedia!: Array<{
    language: 'english' | 'greek' | 'russian';
    socialLinks: SocialLinks[]
  }>;
}
