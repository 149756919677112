import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, exhaustMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TourActions } from './tour.actions';
import { TourService } from '../../services/tour.service';
import { LoaderService } from 'core/services/loader.service';


@Injectable()
export class TourEffects {
  getTours$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TourActions.toursPageFetch),
      tap(() => this.loaderService.setLoading(true)),
      concatMap((action) =>
        this.service.list(action.data).pipe(
          map(data => {
            this.loaderService.setLoading(false);
            return TourActions.toursPageFetchSuccess({data});
          }),
          catchError(error => {
            this.loaderService.setLoading(false);
            return of(TourActions.toursPageFetchFailure({error}));
          }))
      )
    );
  });

  getTourSearchFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TourActions.toursAPIFetchFilters),
      map(() => this.service.initGenericTourInfo())
    )
  }, { dispatch: false });

  getSortOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TourActions.toursAPIFetchSortOptions),
      concatMap(() => (this.service.getSortOptions().pipe(
        map(data => TourActions.toursAPIFetchSortOptionsSuccess({ data }))
      )))
    )
  });

  tourQuickSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TourActions.toursAPIQuickSearch),
      exhaustMap(payload => (this.service.quickSearch(payload.data.query, payload.data.location).pipe(
        map(data => TourActions.toursAPIQuickSearchSuccess({ data })),
        catchError(error => of(TourActions.toursAPIQuickSearchFailure({ error })))
      )))
    )
  });

  onApplySearchFilter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TourActions.applySearchFilters),
      map(payload => {
        const searchParams: Record<string, Array<number | string>> = {};

        for (const key in payload.data) {
          searchParams[key] = payload.data[key].map(item => item.id);
        }

        return { type: TourActions.toursPageFetch.type, data: searchParams };
      }));
  });

  onFetchSelectedTourDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TourActions.toursAPIFetchDetails),
      tap(() => this.loaderService.setLoading(true)),
      exhaustMap((payload) => (
        this.service.getDetails(payload.data.id)
          .pipe(
            map(res => {
              this.loaderService.setLoading(false);
              return TourActions.toursAPIFetchDetailsSuccess({ data: res });
            }),
            catchError(error => {
              this.loaderService.setLoading(false);
              return of(TourActions.toursAPIFetchDetailsFailure({ error }));
            })
          )
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private service: TourService,
    private loaderService: LoaderService
  ) { }
}
