import { createAction, props } from '@ngrx/store';

export const register = createAction(
  '[Register Page] Register',
  props<{ firstName: string; lastName: string; email: string; password: string; }>()
);

export const registerComplete = createAction(
  '[Register API] Register Complete'
);

export const registerFailed = createAction(
  '[Register API] Register Failed',
  props<{ error: unknown }>()
);

