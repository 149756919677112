import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataModule } from "@app/data/data.module";
import { LetDirective } from '@ngrx/component';
import { CoreModule } from 'core/core.module';
import { ErrorInterceptor } from "core/interceptors/http.interceptor";
import { JwtInterceptor } from 'core/interceptors/jwt.interceptor';
import { UnauthorizedInterceptor } from 'core/interceptors/unauthorized.interceptor';
import { LoaderComponent } from 'shared/components/loader/loader.component';
import { FooterComponent } from 'shared/layout/footer/footer.component';
import { HeaderComponent } from 'shared/layout/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BreadCrumbComponent } from './shared/components/bread-crumb/bread-crumb.component';
import { BackToTopComponent } from './shared/components/back-to-top/back-to-top.component';
import { register } from 'swiper/element/bundle';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

register();

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    DataModule.forRoot(),
    BrowserAnimationsModule,
    BreadCrumbComponent,
    HttpClientModule,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    BackToTopComponent,
    LetDirective,
  ],
  providers: [
    provideHttpClient(withFetch()),
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000, horizontalPosition: 'end', verticalPosition: 'top' } }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
