import { Injectable } from "@angular/core";
import { environment } from "environments";
import { Observable } from "rxjs";
import { City, IApiCity, ICity } from "../models";
import { IApiResponse } from "core/models/api.response";
import { map } from "rxjs/operators";
import { HttpService } from 'core/services/http.service';
import { Location } from '@app/data/models/city/city-detail-api';

@Injectable()
export class CityService {
  #baseUrl = `${ environment.api.url}/tour-booking/city-management`;

  constructor(private http: HttpService) {
  }

  getCities(): Observable<ICity[]> {
    return this.http.get<IApiResponse<IApiCity[]>>(this.#baseUrl)
      .pipe(map(res => (res.data?.map(City.fromJson) || [])));
  }

  getCityDetail(id: number): Observable<IApiResponse<Location>> {
    return this.http.get<IApiResponse<Location>>(`${this.#baseUrl}/details/${id}`);
  }
}
