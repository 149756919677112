import { createFeature, createReducer, on } from '@ngrx/store';
import { setUserProfile, updateUserProfile } from './user.actions';
import { User } from '@app/data/models';

export const userFeatureKey = 'user';

export interface UserState {
  details: User | null;
}

export const initialState: UserState = {
  details: null
};

export const reducer = createReducer(
  initialState,
  on(setUserProfile, (state, action): UserState => ({
    ...state,
    details: action.data
  })),
  on(updateUserProfile, (state, action): UserState => ({
    ...state,
    details: action.data
  }))
);

export const userFeature = createFeature({
  name: userFeatureKey,
  reducer,
});

