<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<mat-toolbar>
  <mat-toolbar-row>
    <gp-hermes-logo />
    <div class="menu-container">
      <div class="menu-container__actions">
        <div class="menu-box">
          <button id="menu-btn" mat-icon-button (click)="actionVisibilityMenuMobile('show')">
            <mat-icon>menu</mat-icon>
          </button>
          <div class="overlay" (click)="actionVisibilityMenuMobile('hide')" [ngClass]="{'show': visibilityMenu}"></div>
          <div class="holder-mobile-menu" [ngClass]="{'show': visibilityMenu}">
            <div class="header-holder">
              <button class="close-menu-btn" (click)="actionVisibilityMenuMobile('hide')">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <gp-search-bar />
            <gp-mobile-menu class="gp-mobile-menu" />
          </div>
        </div>
        <div class="menu-box for-desktop">
          <gp-nav-links [links]="links" />
          <gp-profile-link [username]="user?.username" [avatar]="user?.avatar" />
        </div>
        <button mat-icon-button (click)="themeService.toggleMode()" class="theme-toggle">
          <mat-icon>{{(themeService.lightMode() | async) ? 'dark_mode' : 'light_mode'}}</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
