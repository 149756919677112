import { IDevice } from '@app/data/models/auth/device';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  fetchLoginDevicesSuccess,
  signOutFromDeviceSuccess
} from '@app/data/store/auth/auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  devices: IDevice[];
}

export const initialState: AuthState = {
  devices: []
};

export const reducer = createReducer(
  initialState,
  on(fetchLoginDevicesSuccess, (state, action): AuthState => {
    return {
      ...state,
      devices: action.data
    };
  }),
  on(signOutFromDeviceSuccess, ((state, action): AuthState => ({
    ...state,
    devices: state.devices.filter(device => device.id !== action.data.id)
  })))
)

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer
});
