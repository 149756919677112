import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'gp-typing-identifier',
  standalone: true,
  imports: [
    MatIcon,
    MatProgressSpinner
  ],
  template: `
    @if (status() === 'idle') {
      <mat-icon [color]="color()">search</mat-icon>
    } @else if (status() === 'typing') {
      <mat-progress-spinner [color]="color()" mode="indeterminate" [diameter]="25" />
    }
  `
})
export class TypingIdentifierComponent {
  status = input<'idle' | 'typing'>('idle');
  color = input<null | 'primary' | 'accent'>();
}
