import { Injectable } from '@angular/core';
import { User } from '@app/data/models/auth/user';
import { environment } from 'environments';
import { ICredentials } from '@app/data/models/auth/credentials';
import { Observable, throwError } from 'rxjs';
import { SignInApiResponse } from '@app/data/models/auth/sign-in-api-response';
import { IApiResponse } from 'core/models/api.response';
import { catchError, map } from 'rxjs/operators';
import { CreateAccount } from '@app/data/models/auth/create-account';
import { Store } from '@ngrx/store';
import { setUserProfile } from '@app/data/store/user/user.actions';
import { HttpService } from 'core/services/http.service';
import { ChangePasswordRequest } from '@app/data/models/auth/change-password-request';
import { IDevice } from '@app/data/models/auth/device';
import { RefreshTokenApiResponse } from '@app/data/models/auth/refresh-token-api-response';
import { LocalStorageService } from 'core/services/local-storage.service';
import { FileUploadDownload } from '@app/data/models/auth/file-upload-download';
import { ExternalLoginDetail } from '@app/data/models/auth/external-login-detail';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  #user = User.dummy();
  private idpBaseUrl = `${environment.api.url}/auth/authentication-management`;
  private fileBaseUrl = `${environment.api.url}/auth/file-management`;
  private externalAuth = `${environment.api.auth}/external-authentication-management/api/account`;

  // Authentication navigation
  private onAuthSuccessUrl = '/';
  private onAuthFailureUrl = '/auth/login';
  logoutUrl = '/';

  private expiresAt?: number;

  private accessToken: string | null = null;
  private accessTokenFlag = 'accessToken';

  private refreshToken: string | null = null;
  private refreshTokenFlag = 'refreshToken';

  constructor(
    private http: HttpService,
    private store: Store,
    private localStorage: LocalStorageService,
  ) {
    this.setToken(this.localStorage.getItem(this.accessTokenFlag), this.localStorage.getItem(this.refreshTokenFlag));
  }

  get authSuccessUrl(): string {
    return this.onAuthSuccessUrl;
  }

  get authFailureUrl(): string {
    return this.onAuthFailureUrl;
  }

  get authenticated(): boolean {
    return this.accessToken !== null;
  }

  get googleSignInUpUrl() {
    return `${this.externalAuth}/login?externalProvider=1`;
  }

  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  clearToken() {
    this.accessToken = null;
    this.localStorage.removeItem(this.accessTokenFlag);

    this.refreshToken = null;
    this.localStorage.removeItem(this.refreshTokenFlag);
  }

  login(credentials: ICredentials): Observable<SignInApiResponse> {
    return this.http.post<IApiResponse<SignInApiResponse>>(`${this.idpBaseUrl}/sign-in`, {
      data: {
        email: credentials.username,
        password: credentials.password,
        rememberMe: false
      }
    })
      .pipe(
        map(res => {
          if (res.data) {
            this.setToken(res.data.accessToken, res.data.refreshToken);
            this.expiresAt = res.data.expiresIn;
            return res.data;
          }
          else
            throw Error(`Data didn't match the expected interface`, { cause: res });
        }),
        catchError(err => {
          throw Error(`Error on sign in request for ${credentials}`, err);
        })
      );
  }

  setAuth(authResult: SignInApiResponse) {
    if (authResult.accessToken) {
      this.setToken(authResult.accessToken);
      this.store.dispatch(setUserProfile({ data: User.fromJson(authResult) }));
    }
  }

  logout(): Observable<void> {
    return this.http.post<void>(`${this.idpBaseUrl}/sign-out`, {
      context: '',
      data: ''
    });
  }

  requestResetPassword(email: string): Observable<boolean> {
    return this.http.post<IApiResponse<undefined>>(`${this.idpBaseUrl}/send-forget-password-verify-code-by-email`, {
      data: { email: email }
    }).pipe(map(res => (!(res.errors))))
  }

  verifyCodeByEmail(email: string, verifyCode: string, hash: string): Observable<boolean> {
    return this.http.post<IApiResponse<undefined>>(`${this.idpBaseUrl}/verify-forget-password-verify-code-by-email`, {
      email,
      verifyCode,
      hash
    }).pipe(map(res => (!(res.errors))));
  }

  resetPassword(email: string, newPassword: string, confirmNewPassword: string, verifyCode: string): Observable<boolean> {
    return this.http.post<IApiResponse<undefined>>(`${this.idpBaseUrl}/reset-password-by-email`, {
      data: {
        email,
        newPassword,
        confirmNewPassword,
        verifyCode
      }
    }).pipe(map(res => (!(res.errors))));
  }

  changePassword(request: ChangePasswordRequest): Observable<unknown> {
    return this.http.post<IApiResponse<unknown>>(`${this.idpBaseUrl}/change-password`, {
      data: {
        oldPassword: request.currentPassword,
        newPassword: request.newPassword,
        confirmNewPassword: request.confirmNewPassword,
      }
    });
  }

  register(data: CreateAccount): Observable<boolean> {
    return this.http
      .post<IApiResponse<undefined>>(`${this.idpBaseUrl}/sign-up`, {
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
        }
      })
      .pipe(
        map((res) => (!(res.errors))),
        catchError((error) => {
          console.error('Error on Register Request', error);
          return throwError(() => false);
        })
      );
  }

  setToken(aToken: string | null, rToken?: string | null): void {
    if (aToken) {
      this.accessToken = aToken;
      this.localStorage.setItem(this.accessTokenFlag, aToken);
    }
    if (rToken) {
      this.refreshToken = rToken;
      this.localStorage.setItem(this.refreshTokenFlag, rToken);
    }
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  user(): User {
    return this.#user;
  }

  getLoginDevices(): Observable<IDevice[]> {
    return this.http.get<IApiResponse<IDevice[]>>(`${this.idpBaseUrl}/log-in-devices`)
      .pipe(map(res => {
        if (res.data) {
          return res.data;
        } else {
          throw Error('Not the response that expected', { cause: res });
        }
      }));
  }

  signOutDevice(id: string): Observable<unknown> {
    return this.http.get<IApiResponse<undefined>>(`${this.idpBaseUrl}/sign-out-on-device?deviceId=${id}`);
  }

  signOutAllDevices(): Observable<unknown> {
    return this.http.get(`${this.idpBaseUrl}/sign-out-all-device`);
  }

  refreshAccessToken(): Observable<string | null> {
    return this.http.post<IApiResponse<RefreshTokenApiResponse>>(`${this.idpBaseUrl}/refresh-token`, {
      data: { refreshToken: this.refreshToken },
    })
      .pipe(
        map(res => {
          if (res.data) {
            this.setToken(res.data.accessToken, res.data.refreshToken);
            this.expiresAt = res.data.expiresIn;
            return res.data.accessToken;
          } else {
            return null;
          }
        })
      )
  }

  uploadFile(data: FormData): Observable<string> {
    return this.http.post<IApiResponse<FileUploadDownload>>(`${this.fileBaseUrl}/upload`, data)
      .pipe(map(res => {
        if (res.data) {
          return res.data.name;
        } else {
          throw Error('Not uploaded file');
        }
      }));
  }

  downloadFile(fileName: string): Observable<string> {
    return this.http.post<IApiResponse<FileUploadDownload>>(`${this.fileBaseUrl}/download?fileName=${fileName}`, {})
      .pipe(map(res => {
        if (res.data) {
          return res.data.name;
        } else {
          throw Error('Not uploaded file');
        }
      }));
  }

  fetchExternalLoginDetails(loginId: string): Observable<ExternalLoginDetail> {
    return this.http.get<IApiResponse<ExternalLoginDetail>>(`${this.externalAuth}/get-login-data?loginid=${loginId}`)
      .pipe(map(res => {
        if (res.data) {
          return res.data;
        } else {
          throw Error('')
        }
      }))
  }
}
