import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

interface SnackbarData {
  status: 'success' | 'error';
  message: string;
  showIcon: boolean;
}

@Component({
  selector: 'gp-snackbar',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent implements OnInit {
  status: SnackbarData['status'] = 'success';
  message = 'Action successfully done';
  showIcon = true;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {
  }

  ngOnInit() {
    this.status = this.data.status;
    this.message = this.data.message;
    this.showIcon = this.data.showIcon;
  }
}
