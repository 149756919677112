export interface SignInApiResponse {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  roles: unknown[] | null;
  otpType: number;
  messages: unknown[];
  dateOfBirth: string | null;
  emailAddress: string;
  expiresIn: number;
  firstName: string;
  imageUrl: string;
  imageName: string;
  lastName: string; // You might want to replace `any[]` with a more specific type if possible
  phoneNumber: string;
  phoneNumberCountryCode: string;
  // missing pnc // You might want to replace `any[]` with a more specific type if possible
  successed: boolean;
}
