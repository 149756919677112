import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './import.guard';
import { ThemeService } from 'core/services/theme.service';
import { extModules } from '../build-specifics';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthLoginEffects, AuthLogoutEffects } from '@app/views/auth/state/effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpService } from 'core/services/http.service';

@NgModule({
  imports: [
    extModules,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([
      AuthLoginEffects,
      AuthLogoutEffects
    ]),
    MatDialogModule,
    MatSnackBarModule
  ],
  exports: [
    extModules,
    StoreModule,
    EffectsModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ThemeService,
        HttpService
      ]
    } as ModuleWithProviders<CoreModule>;
  }
}
