import { createFeature, createReducer, on } from '@ngrx/store';
import { TourActions } from './tour.actions';
import { Tour, City, TourDetailed } from '../../models';
import { TourSearchFilter } from '@app/data/models/tour/tour-search-filters';
import { SortOption } from '@app/data/models/tour/sort-option';
import { TourCategory } from '@app/data/models/tour/tour-category';
import { ITourSearchApiResponse } from '@app/data/models/tour/tour-quick-search-api-response';
import { ApplySearchFilter } from '@app/data/models/tour/apply-search-filter';
import { TourSelectedCity } from '@app/data/models/tour/tour-selected-city';

export const tourFeatureKey = 'tour';

export interface TourState {
  tours: Array<Tour>;
  search: {
    filters?: TourSearchFilter[];
    selectedCity?: TourSelectedCity;
    appliedSearchFilters?: ApplySearchFilter;
    sortOptions?: SortOption[];
    tourCategories?: TourCategory[];
    filteredTours?: Array<unknown>;
    sortedBy?: SortOption;
    dates?:  Date | string | number;
    location?: {
      id?: string;
      name?: string;
      img?: string
    }
    pageNumber?: number;
    pageSize?: number;
    total?: number;
    quickSearchResponse?: ITourSearchApiResponse;
  };
  city: City | null;
  selectedTourDetails: TourDetailed | null;
}

export const initialState: TourState = {
  tours: [],
  search: {},
  city: null,
  selectedTourDetails: null
};

export const reducer = createReducer(
  initialState,
  on(TourActions.toursPageFetchSuccess, (state, action): TourState => ({
    ...state,
    tours: action.data
  })),
  on(TourActions.toursAPIFetchFiltersSuccess, (state, action): TourState => ({
    ...state,
    search: {
      ...state.search,
      filters: action.data
    }
  })),
  on(TourActions.toursAPIFetchSortOptionsSuccess, (state, action): TourState => ({
    ...state,
    search: {
      ...state.search,
      sortOptions: action.data
    }
  })),
  on(TourActions.setCategories, (state, action): TourState => ({
    ...state,
    search: {
      ...state.search,
      tourCategories: action.data
    }
  })),
  on(TourActions.setPagination, (state, action): TourState => ({
    ...state,
    search: {
      ...state.search,
      pageNumber: action.data.index,
      pageSize: action.data.size,
      total: action.data.total
    }
  })),
  on(TourActions.toursAPIQuickSearchSuccess, (state, action): TourState => ({
    ...state,
    search: {
      ...state.search,
      quickSearchResponse: action.data
    }
  })),
  on(TourActions.applySearchFilters, (state, action): TourState => ({
    ...state,
    search: {
      ...state.search,
      appliedSearchFilters: action.data
    }
  })),
  on(TourActions.toursAPIFetchDetailsSuccess, (state, action): TourState => ({
    ...state,
    selectedTourDetails: action.data
  })),
  on(TourActions.toursAPIFetchDetails, (state): TourState => ({
    ...state,
    selectedTourDetails: null
  })),
  on(TourActions.toursSetSelectedCity, (state, action): TourState => ({
    ...state,
    search: {
      ...state.search,
      selectedCity: action.data?.selectedCity
    }
  })),
);

export const tourFeature = createFeature({
  name: tourFeatureKey,
  reducer,
});

