import { SignInApiResponse } from '@app/data/models';

export interface IUser {
  email: string;
  isOAuth: boolean;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  avatarFileName?: string;
  phoneNumber?: string;
  phoneNumberCountryCode?:string;
  dateOfBirth?: Date | null;
}

export class User {
  email?: string;
  isOAuth?: boolean;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  avatarFileName?: string;
  phoneNumber: string;
  phoneNumberCountryCode:string;
  dateOfBirth?: Date;

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  static create(data: IUser): User {
    const user = new User();
    return Object.assign(user, data);
  }

  static dummy(): User {
    return User.create({
      email: 'john@doe.com',
      isOAuth: false,
      firstName: 'John',
      lastName: 'Doe',
      dateOfBirth: new Date()
    });
  }

  static fromJson(data: SignInApiResponse): User {
    return User.create({
      email: data.emailAddress,
      isOAuth: false,
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth) : null,
      phoneNumber: data.phoneNumber,
      phoneNumberCountryCode: data.phoneNumberCountryCode,
      avatar: data.imageUrl,
      avatarFileName: data.imageName
    })
  }
}
