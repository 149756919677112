import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from 'shared/material.module';
import { SearchBarComponent } from 'shared/components/search-bar/search-bar.component';
import { HermesLogoComponent } from './hermes-logo/hermes-logo.component';
import { NavLinksComponent } from 'shared/layout/header/nav-links/nav-links.component';
import { ProfileLinkComponent } from 'shared/layout/header/profile-link/profile-link.component';
import { ThemeService } from 'core/services/theme.service';
import { MatMenuModule } from '@angular/material/menu';
import { MobileMenuComponent } from 'shared/layout/header/mobile-menu/mobile-menu.component';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'gp-header',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatIconModule, MaterialModule, SearchBarComponent, HermesLogoComponent, NavLinksComponent, ProfileLinkComponent, MatMenuModule, MobileMenuComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  @Input() withSearchbar = false;
  @Input() user?: { username: string; avatar: string };
  @Input() links = this.defaultLinks();

  // boolean
  visibilityMenu: boolean = false;

  constructor(
    public themeService: ThemeService,
    private router: Router,
  ) {
    this.checkRouter();
  }

  actionVisibilityMenuMobile(event: string): void {
    if (event === 'show') {
      this.visibilityMenu = true;
    } else {
      this.visibilityMenu = false;
    }
  }

  private defaultLinks() {
    return [
      {
        label: 'Tours',
        url: '/tours'
      },
      // {
      //   label: 'Flight',
      //   url: '/flight'
      // },
      {
        label: 'About us',
        url: '/about-us'
      },
      {
        label: 'Contact us',
        url: '/contact-us'
      }
    ]
  }

  private checkRouter(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.actionVisibilityMenuMobile('hide');
      }
    });
  }
}
