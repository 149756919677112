import { ICity } from './city.interface';
import { IApiCity } from './api.city.interface';

export class City implements Partial<ICity> {
  static create(data: ICity): ICity {
    const city = new City();
    return Object.assign(city, data);
  }

  static dummy(): ICity {
    return City.create({
      id: crypto.randomUUID(),
      name: 'Cyprus',
      countryId: crypto.randomUUID(),
      isPublished: true,
      images: [ {
        type: 1,
        imageUrl: 'https://greekcitytimes.com/wp-content/uploads/2022/05/19-1.jpg'
      } ]
    });
  }

  static fromJson(data: IApiCity): ICity {
    return City.create(data);
  }
}
