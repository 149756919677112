<h3 mat-dialog-title i18n>Log Out</h3>

<mat-dialog-content i18n>
  Are you sure you want to log out?
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="cancel()" i18n>
    No
  </button>
  <button mat-button (click)="confirm()" i18n>
    Yes
  </button>
</mat-dialog-actions>
