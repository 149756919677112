export const environment = {
  production: false,
  api: {
    url: 'https://gp-my-api.nt-development.dev/api/v1',
    auth: 'https://gp-auth-api.nt-development.dev'
  },
  google: {
    mapKey: 'AIzaSyDNIYVCwwGC-52Rsa_jWUbQsS7s1mqBMJE'
  }
};
