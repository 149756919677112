<button class="profile-link" mat-button [matMenuTriggerFor]="menu">
  <span class="content-wrapper">
    <span class="profile-img">
      <img *ngIf="avatar; else person" [ngSrc]="avatar" [width]="24" [height]="24" alt="User Profile">
      <ng-template #person>
        <img src="../../../../../assets/images/User.svg" alt="profile">
      </ng-template>
    </span>
    {{username || 'Account'}}
  </span>
</button>
<mat-menu #menu class="account-menu">
  <ng-container *ngFor="let menuItem of menuItems">
    <button mat-menu-item (click)="onItemClick(menuItem)">
      <mat-icon svgIcon="{{menuItem.icon}}"></mat-icon>
      <p>{{menuItem.label}}</p>
    </button>
  </ng-container>
</mat-menu>