import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Link } from 'shared/layout/footer/footer.component';

@Component({
  selector: 'gp-link-column',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `
    <header class="gp-title-h5 gp-semi-bold">{{title}}</header>
    <ul>
      <li *ngFor="let link of links">
        <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
        <a [href]="link.url || ''">{{link.label}}</a>
      </li>
    </ul>
  `,
  styleUrls: ['../footer.component.scss']
})
export class LinkColumnComponent {
  @Input() title!: string;
  @Input() links!: Link[];
}
