import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateGuard } from 'core/services/auth.guard';
import { PageNotFoundComponent } from '@app/views/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./views/landing/landing.module')
        .then(m => m.LandingModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/auth/auth.module')
        .then(m => m.AuthModule)
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./views/profile/profile.module')
        .then(x => x.ProfileModule),
    canActivate: [canActivateGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'checkout',
    // canActivate: [canActivateLanding],
    loadChildren: () =>
      import('./views/checkout/checkout.module')
        .then(x => x.CheckoutModule),
    canActivate: [canActivateGuard],
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./views/my-booking/my-booking.module')
        .then(m => m.MyBookingModule),
    data: {
      breadcrumb: 'My Booking',
    }
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./views/contact-us/contact-us.module')
        .then(m => m.ContactUsModule)
  },
  {
    path: 'private-policy',
    loadChildren: () =>
      import('./views/private-policy/private-policy.module')
        .then(m => m.PrivatePolicyModule)
  },
  {
    path: 'tours',
    loadChildren: () =>
      import('./views/tours/tours.module')
        .then(m => m.ToursModule)
  },
  {
    path: 'tour-detail/:id',
    loadComponent: () =>
      import('./views/tour-details/tour-details.component')
        .then(c => c.TourDetailsComponent)
  },
  {
    path: 'about-us',
    loadComponent: () =>
      import('./views/about-us/about-us.component')
        .then(c => c.AboutUsComponent)
  },
  {
    path: 'articles',
    loadComponent: () => import('./views/flight/articles/articles.component').then(com => com.ArticlesComponent)
  },
  {
    path: 'flight',
    loadComponent: () => import('./views/flight/landing/flight-landing.component').then(com => com.FlightLandingComponent)
  },
  {
    path: 'booking-process',
    loadComponent: () => import('./views/flight/booking-process/booking-process.component').then(com => com.BookingProcessComponent)
  },
  {
    path: 'flight-list',
    loadComponent: () => import('./views/flight/flights/flights.component').then(com => com.FlightsComponent)
  },
  {
    path: 'download',
    loadComponent: () => import('./views/download/download.component').then(com => com.DownloadComponent)
  },
  {
    path: 'terms',
    loadComponent: () => import('./views/terms/terms.component').then(com => com.TermsComponent)
  },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
