import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { tap, exhaustMap, map } from 'rxjs/operators';

import { LogoutPromptComponent } from '@app/views/auth/shared/components/logout-prompt/logout-prompt.component';
import { AuthService } from '@app/data/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { logout, logoutCancelled, logoutConfirmed } from '../actions';
import { Router } from '@angular/router';
import { LoaderService } from 'core/services/loader.service';

@Injectable()
export class AuthLogoutEffects {
  logoutConfirmation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      exhaustMap(() => {
        return this.dialogService
          .open(LogoutPromptComponent)
          .afterClosed()
          .pipe(map(confirmed => {
            return confirmed ? {type: logoutConfirmed.type} : {type: logoutCancelled.type};
          }));
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logoutConfirmed),
      exhaustMap(() => {
        this.loaderService.setLoading(true);
        // TODO: Implement a solution to have dynamic redirect after logout
        return this.authService.logout()
          .pipe(
            tap(() => this.router
              .navigateByUrl(this.authService.logoutUrl)
              .then(() => {
                this.loaderService.setLoading(false);
                this.authService.clearToken();
              }))
          );
      }));
  }, {dispatch: false});

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private dialogService: MatDialog,
    private router: Router,
    private loaderService: LoaderService
  ) {
  }
}
