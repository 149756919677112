import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'shared/material.module';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'gp-nav-links',
  standalone: true,
  imports: [CommonModule, MaterialModule, RouterLink, RouterLinkActive],
  template: `
    <ng-container *ngFor="let link of links; index as i">
      <button mat-flat-button class="gp-text-body1 nav-link" [routerLink]="link.url" [routerLinkActive]="'active'">{{link.label}}</button>
    </ng-container>
  `,
  styles: [`
    .active {
      border-bottom: 3px solid var(--c-primary-40);
    }
    button {
      background-color: rgba(0,0,0,0) !important;
      border-radius: 0;
    }
    .nav-link{
      color:var(--c-text-secondary) !important;
    }
  `]
})
export class NavLinksComponent {
  @Input() links!: { label: string; url: string; }[];
}
