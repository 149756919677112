import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@app/data/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  resetPassword,
  resetPasswordComplete,
  resetPasswordFailed
} from '@app/views/auth/state/actions';
import { exhaustMap, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SnackbarComponent } from 'shared/components/snackbar/snackbar.component';
import { Injectable } from '@angular/core';
import { LoaderService } from 'core/services/loader.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthResetPasswordEffects {
  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetPassword),
      exhaustMap((payload) => {
        this.loader.setLoading(true);
        return this.authService.resetPassword(payload.email, payload.newPassword, payload.confirmNewPassword, payload.verificationCode)
          .pipe(
            map(() => {
              return { type: resetPasswordComplete.type }
            }),
            catchError(() => of({ type: resetPasswordFailed.type, error: 'Reset password request failed' }))
          )
      }))
  });

  resetPasswordComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetPasswordComplete),
      tap(() => {
        this.loader.setLoading(false);
        this.snackbar.openFromComponent(SnackbarComponent, {
          data: {
            message: $localize`Your password has been successfully updated`,
            status: 'success',
            showIcon: true
          },
          duration: 2500,
          horizontalPosition: 'end',
          verticalPosition: 'top'
        });
        this.router.navigateByUrl("/auth/login");
      })
    )
  }, {dispatch: false});

  resetPasswordFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetPasswordFailed),
      tap(() => {
        this.loader.setLoading(false);
      })
    )
  }, {dispatch: false});

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private loader: LoaderService,
    private router: Router
  ) {}
}
