import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ICity } from "../../models";

export const CityActions = createActionGroup({
  source: 'City',
  events: {
    'Load Cities': emptyProps(),
    'Load Cities Success': props<{ data: Array<ICity> }>(),
    'Load Cities Failure': props<{ error: unknown }>(),
    'Select City': props<{ data: ICity }>()
  }
});
