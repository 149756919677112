<header class="gp-title-h5 gp-semi-bold">Social media</header>
<div class="channels-wrapper">
  <section *ngFor="let channel of socialMedia">
    <header class="gp-title-h6 gp-semi-bold">{{channel.language | titlecase}}</header>
    <ul class="social-channel-list">
      <li *ngFor="let link of channel.socialLinks">
        <a [href]="link.url" target="_blank">
          <mat-icon [svgIcon]="link.icon"/>
        </a>
      </li>
    </ul>
  </section>
</div>
