import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store, StoreModule } from '@ngrx/store';
import { logout } from '@app/views/auth/state/actions';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@app/data/services/auth.service';

interface MenuItem {
  icon: string;
  label: string;
  link: string;
  callback?: () => void
}

interface Menu {
  title: string;
  child: MenuItem[];
}

@Component({
  selector: 'gp-mobile-menu',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatMenuModule, StoreModule, RouterLink],
  template: `
    <ng-container *ngFor="let menuItem of menuItems">
    <div class="holder-group-menu">
      <h3 class="title-section-menu" [title]="menuItem.title">{{menuItem.title}}</h3>
      <ng-container *ngFor="let childItem of menuItem.child">
        <ng-container *ngIf="childItem?.callback else noCallback">
          <button mat-menu-item (click)="childItem?.callback()">
            <mat-icon>{{childItem.icon}}</mat-icon>
            {{childItem.label}}
          </button>
        </ng-container>
        <ng-template #noCallback>
          <ng-container *ngIf="childItem.link">
            <a mat-menu-item [routerLink]="childItem.link">
              <mat-icon svgIcon="{{childItem.icon}}"></mat-icon>
              {{childItem.label}}
            </a>
          </ng-container>
        </ng-template>
      </ng-container>
      </div>
    </ng-container>
  `,
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  defaultMenu = [
    {
      title: 'Quick access',
      child: []
    },
    {
      title: 'Content',
      child: [
        {
          icon: 'home',
          label: 'Home',
          link: '/'
        },
        {
          icon: 'map',
          label: 'Tours',
          link: '/tours'
        },
        // {
        //   icon: 'flight',
        //   label: 'Flight',
        //   link: '/flight'
        // }
      ]
    },
    {
      title: 'Support',
      child: [
        {
          icon: 'info',
          label: 'About us',
          link: '/about-us'
        },
        {
          icon: 'call',
          label: 'Contact us',
          link: '/contact-us'
        },
      ]
    },
  ];

  notAuthorizedQA = [
    {
      icon: 'enable',
      label: 'Log in',
      link: '/auth/login'
    },
    {
      icon: 'touch_app',
      label: 'Sign up',
      link: '/auth/register'
    },
  ]

  authorizedQA = [
    {
      icon: 'person',
      label: 'Profile',
      link: '/profile'
    },
    {
      icon: 'bookmarks',
      label: 'History',
      link: '/booking'
    },
    {
      icon: 'logout',
      label: 'Log out',
      link: '',
      callback: this.onLogout.bind(this)
    }
  ];

  get menuItems(): Menu[] {
    if (this.authService.authenticated) {
      this.defaultMenu[0].child = this.authorizedQA;
      return this.defaultMenu;
    } else {
      this.defaultMenu[0].child = this.notAuthorizedQA;
      return this.defaultMenu;
    }
  }

  constructor(
    private store: Store,
    private matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private authService: AuthService,
  ) {
    this.handleIconMenu();
  }

  onLogout() {
    this.store.dispatch(logout());
  }

  private handleIconMenu(): void {
    this.matIconRegistry.addSvgIcon(
      'history',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/history.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'enable',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/enable.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'touch_app',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/touch_app.svg')
    )
    // =======
    this.matIconRegistry.addSvgIcon(
      'home',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-mobile/home.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'map',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-mobile/map.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'tour',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-mobile/tour.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'flight',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-mobile/flight-3c.svg')
    );

    // =======
    this.matIconRegistry.addSvgIcon(
      'info',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-mobile/info.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'call',
      this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-mobile/call.svg')
    );
  }
}
