import { createAction, props } from '@ngrx/store';

export const reset = createAction(
  '[Forgot Page] Reset',
  props<{ email: string }>()
);

export const resetComplete = createAction('[Forgot API] Reset Complete');

export const resetFailed = createAction(
  '[Forgot API] Reset Failed',
  props<{ error: unknown }>()
);
