import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BusinessHour } from 'shared/layout/footer/footer.component';

@Component({
  selector: 'gp-footer-business-hour',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './business-hour.component.html',
  styleUrls: ['../footer.component.scss', './business-hour.component.scss']
})
export class BusinessHourComponent {
  @Input() businessHour!: BusinessHour[];
}
