import { Injectable, signal } from '@angular/core';
import { LocalStorageService } from 'core/services/local-storage.service';
import { SearchSuggestion } from '@app/data/models/tour/search-suggestion';

const RECENT_SEARCH_KEY = 'recentSearch';

@Injectable()
export class RecentSearchService {
  recentSearch$ = signal<SearchSuggestion[]>([]);

  constructor(private localStorage: LocalStorageService) {
    this.restoreRecentSearch();
  }

  private restoreRecentSearch() {
    const storedRecentSearch = this.localStorage.getItem<SearchSuggestion[]>(RECENT_SEARCH_KEY);

    if (storedRecentSearch) {
      this.recentSearch$.set(storedRecentSearch);
    }
  }

  addRecentSearch(recentSearch: SearchSuggestion) {
    this.recentSearch$.update((state) => {
      // If item already in stack
      if (state.findIndex(item => item.id === recentSearch.id) >= 0) {
        return state;
      }

      state.unshift(recentSearch);
      if (state.length > 3) {
        state.length = 3;
      }
      this.localStorage.setItem(RECENT_SEARCH_KEY, state);
      return state;
    });
  }

  removeRecentSearch(recentSearch: SearchSuggestion) {
    this.recentSearch$.update(state => {
      const filtered = state.filter(item => item.id !== recentSearch.id);
      this.localStorage.setItem(RECENT_SEARCH_KEY, filtered);
      return filtered;
    });
  }

  clearRecentSearch() {
    this.recentSearch$.set([]);
    this.localStorage.removeItem(RECENT_SEARCH_KEY);
  }
}
