import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'gp-hermes-logo',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RouterLink],
  template: `
    <a routerLink="/">
      <img [ngSrc]="'/assets/images/gp_hermes_logo.webp'" [width]="125" [height]="30" alt="GP Hermes Logo"/>
    </a>
  `
})
export class HermesLogoComponent {

}
