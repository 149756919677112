import { TimesOfDayType } from '@app/data/models/tour/tour-time-of-day';
import { IdName, mapToIdName } from '@app/data/models/shared/id-name';
import { TourGenericInfo, TourInfoApiResponse } from '@app/data/models/tour/tour-info-api-response';

export function mapApiInfoToFilter(apiResponse: Partial<TourInfoApiResponse>): TourSearchFilter[] {
  const filters: TourSearchFilter[] = [];

  if (apiResponse.cities) {

    /* Uncomment to let users select the current location
    apiResponse.cities.push({
      id: 'user_location',
      name: 'Use current location'
    });*/
    filters.push({
      category: $localize`Search by Cities`,
      categoryKey: 'cities',
      filters: Object.entries(apiResponse.cities).map(mapToIdName)
    });
  }

  if (apiResponse.interests) {
    filters.push({
      category: $localize`Search by Interests`,
      categoryKey: 'interests',
      filters: Object.entries(apiResponse.interests).map(mapToIdName)
    });
  }

  if (apiResponse.categories) {
    filters.push({
      category: 'Search by Categories',
      categoryKey: 'categories',
      filters: Object.entries(apiResponse.categories).map(mapToIdName)
    });
  }

  return filters;
}

export function mapTourGenericInfoToSearchFilter(genericInfo: Partial<TourGenericInfo>): TourSearchFilter[] {
  return Object.entries(genericInfo).map(info => ({
    category: info[0],
    categoryKey: info[0],
    filters: Array.isArray(info[1]) ? info[1] : []
  }))
}

export interface TourSearchFilter {
  category: string;
  categoryKey: string;
  filters: IdName[];
}

export interface TourSearchAppliedFilters {
  query: string | null;
  startDate: string | null;
  timesOfDayType: TimesOfDayType | null;
  paging: { size: 5 | 10 | 20 | 50 | 100; index: number; } | null;
  isTop: boolean;
  interests: number[] | null;
  cities: number[] | null;
}
