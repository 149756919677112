import { createFeature, createReducer, on } from '@ngrx/store';
import { CityActions } from './city.actions';
import { City } from "../../models";

export const cityFeatureKey = 'city';

export interface CityState {
  cities: Array<City>;
  selectedCity: City | null;
}

export const initialState: CityState = {
  cities: [],
  selectedCity: null,
};

export const reducer = createReducer(
  initialState,
  on(CityActions.loadCities, (state): CityState => state),
  on(CityActions.loadCitiesSuccess, (state): CityState => state),
  on(CityActions.loadCitiesFailure, (state): CityState => state),
  on(CityActions.selectCity, (state, action): CityState => ({
    cities: state.cities,
    selectedCity: action.data,
  }))
);

export const cityFeature = createFeature({
  name: cityFeatureKey,
  reducer,
});

