import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'gp-download-app',
  standalone: true,
  template: `
    <a [href]="downloadLink" aria-label="Click to download the GP Hermes Application on Store">
      <img
        alt="Download the GP Hermes Application on {{storeName.replace('-', ' ')}}"
        [ngSrc]="'/assets/images/' + storeName + '.png'"
        [width]="150"
        [height]="45"
      />
    </a>
  `,
  imports: [NgOptimizedImage],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadAppComponent {
  @Input() storeName: 'app-store' | 'google-play' = 'google-play';
  @Input() downloadLink = 'https://play.google.com/';
}
