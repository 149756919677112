import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DownloadAppComponent } from 'shared/components/download-app/download-app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SocialMediaComponent } from 'shared/layout/footer/social-media/social-media.component';
import { BusinessHourComponent } from 'shared/layout/footer/business-hour/business-hour.component';
import { LinkColumnComponent } from 'shared/layout/footer/link-column/link-column.component';

export type Link = {
  label: string;
  url?: string;
  icon?: string;
}

export type SocialLinks = {
  icon: 'facebook' | 'instagram' | 'youtube' | 'linkedin' | 'whatsapp' | 'telegram';
  url: string;
}

export type BusinessHour = { days: string, working: string };

@Component({
  selector: 'gp-footer',
  standalone: true,
  imports: [CommonModule, MatIconModule, NgOptimizedImage, DownloadAppComponent, SocialMediaComponent, BusinessHourComponent, LinkColumnComponent],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  copyRight = `Copyright © ${new Date().getFullYear()}. GPHermes Travel and Tours.`;

  quickLinks: Link[] = [
    {
      label: 'About Us',
      url: '/about-us'
    },
    {
      label: 'Our App',
      url: '/download'
    },
    {
      label: 'Contact Us',
      url: '/contact-us'
    },
    {
      label: 'Privacy Policy',
      url: '/private-policy'
    },
    {
      label: 'Terms & Conditions',
      url: '/terms'
    },
    {
      label: 'Help Center',
      url: '#'
    }
  ];
  contactInfo: Link[] = [
    {
      label: '+357 25584727',
      url: 'tel:+35725584727',
      icon: 'call'
    },
    {
      label: '+357 99618457',
      url: 'tel:+35799618457',
      icon: 'call'
    },
    {
      label: 'info@hermestravel.org',
      url: 'mailto:info@hermestravel.org',
      icon: 'mail'
    }
  ];

  businessHour: BusinessHour[] = [
    {
      days: 'Monday - Friday',
      working: '9am - 6pm'
    },
    {
      days: 'Saturday & Sunday',
      working: 'Closed'
    }
  ];

  socialMedia: Array<{
    language: 'english' | 'greek' | 'russian';
    socialLinks: SocialLinks[]
  }> = [
    {
      language: 'english',
      socialLinks: [
        {icon: 'facebook', url: 'https://www.facebook.com/GPHermes'},
        {icon: 'instagram', url: 'https://www.instagram.com/gp.hermes/'},
        {icon: 'youtube', url: 'https://www.youtube.com/channel/UC8iPGwHH7lS-XLesfhBqY-w'},
        {icon: 'linkedin', url: 'https://www.linkedin.com/company/gp-hermes-travel-tours/'},
        {icon: 'whatsapp', url: 'https://wa.me/35796694278'}
      ]
    },
    {
      language: 'greek',
      socialLinks: [
        {icon: 'facebook', url: 'https://www.facebook.com/gp.hermes.cy'},
        {icon: 'instagram', url: 'https://www.instagram.com/cy.hermes/'},
      ]
    },
    {
      language: 'russian',
      socialLinks: [
        {icon: 'telegram', url: 'https://t.me/hermestraveltours'}
      ]
    }
  ];

  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.initSocialIcons();
  }

  initSocialIcons(): void {
    const availableIcons = [
      'facebook',
      'instagram',
      'linkedin',
      'telegram',
      'whatsapp',
      'youtube'
    ];

    availableIcons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)
      );
    });
  }
}
