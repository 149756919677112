import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Tour, TourDetailed, TourListParameters } from '../../models';
import { TourSearchFilter } from '@app/data/models/tour/tour-search-filters';
import { SortOption } from '@app/data/models/tour/sort-option';
import { TourCategory } from '@app/data/models/tour/tour-category';
import { SearchPagination } from '@app/data/models/tour/tour-search-pagination';
import { ITourSearchApiResponse } from '@app/data/models/tour/tour-quick-search-api-response';
import { ApplySearchFilter } from '@app/data/models/tour/apply-search-filter';
import { TourSelectedCity } from '@app/data/models/tour/tour-selected-city';

export const TourActions = createActionGroup({
  source: 'Tours',
  events: {
    'Tours Page Fetch': props<{ data: TourListParameters }>(),
    'Tours Page Fetch Success': props<{ data: Tour[] }>(),
    'Tours Page Fetch Failure': props<{ error: unknown }>(),
    'Tours API Fetch Filters': emptyProps(),
    'Tours API Fetch Filters Success': props<{ data: TourSearchFilter[] }>(),
    'Tours API Fetch Filters Failure': props<{ error: unknown }>(),
    'Tours API Fetch Sort Options': emptyProps(),
    'Tours API Fetch Sort Options Success': props<{ data: SortOption[] }>(),
    'Set Categories': props<{ data: TourCategory[] }>(),
    'Set Pagination': props<{ data: SearchPagination }>(),
    'Tours API Quick Search': props<{ data: { query: string , location:boolean } }>(),
    'Tours API Quick Search Success': props<{ data: ITourSearchApiResponse }>(),
    'Tours API Quick Search Failure': props<{ error: unknown }>(),
    'Apply Search Filters': props<{ data: ApplySearchFilter }>(),
    'Tours API Fetch Details': props<{ data: { id: number } }>(),
    'Tours API Fetch Details Success': props<{ data: TourDetailed }>(),
    'Tours API Fetch Details Failure': props<{ error: unknown }>(),
    'Tours Set Selected City': props<{ data?: { selectedCity?: TourSelectedCity }}>()
  }
});
