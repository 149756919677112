import {
  TourAvailableOptionApi
} from '@app/data/models/tour/check-availability-api-response';

export class TourAvailability {
  id: number;
  dayTimeType: number;
  departTime: string;
  duration: string;

  isPrivateTour: boolean;
  phoneNumber: string;

  static dummy(): TourAvailability {
    const tourAvailability = new TourAvailability();

    tourAvailability.id = new Date().getTime() * Math.floor(Math.random() * 10000);
    tourAvailability.dayTimeType = 1;
    tourAvailability.departTime = '18:00pm';
    tourAvailability.duration = '4 hours';
    return tourAvailability;
  }

  static privateCallOption(): TourAvailability {
    const tourAvailability = new TourAvailability();

    tourAvailability.id = new Date().getTime() * Math.floor(Math.random() * 10000);
    tourAvailability.dayTimeType = 1;
    tourAvailability.departTime = '18:00pm';
    tourAvailability.duration = '4 hours';

    tourAvailability.isPrivateTour = true;
    tourAvailability.phoneNumber = '+357 25212566 \n +357 96694278';

    return tourAvailability;
  }

  static fromJson(data: TourAvailableOptionApi): TourAvailability {
    const tourAvailability = new TourAvailability();

    tourAvailability.id = data.id
    tourAvailability.dayTimeType = data.timeOfDay;
    tourAvailability.departTime = data.departTime;
    tourAvailability.duration = data.duration;

    return tourAvailability;
  }
}
