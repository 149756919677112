import { TourApi } from './tour-api';
import { PageSize } from './tour-search-pagination';

export interface TourListApiResponse {
  categories: Record<number, string>;
  tours: {
    items: TourApi[];
    base: {
      total: number;
      index: number;
      size: PageSize;
    };
  };
}
