import { createAction, props } from '@ngrx/store';
import { SignInApiResponse } from '@app/data/models/auth/sign-in-api-response';

export const login = createAction(
  '[Login Page] Login',
  props<{ username: string; password: string; returnUrl?: string; }>()
);

export const loginComplete = createAction(
  '[Login Page] Login Complete',
  props<{ payload: { response: SignInApiResponse; returnUrl?: string } }>()
);

export const loginSuccess = createAction(
  '[Auth API] Login Success',
  props<{ returnUrl?: string }>()
);

export const loginFailure = createAction(
  '[Auth API] Login Failure',
  props<{ payload: {error: unknown, returnUrl?: string} }>()
);

export const fetchExternalLoginDetails = createAction(
  '[Login Page] Success External Login',
  props<{ payload: { loginId: string }}>()
)
