import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@app/data/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { reset, resetComplete, resetFailed } from '../actions';
import { exhaustMap, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SnackbarComponent } from 'shared/components/snackbar/snackbar.component';
import { Router } from '@angular/router';

@Injectable()
export class AuthForgotEffects {
  reset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reset),
      exhaustMap((payload) => this.authService.requestResetPassword(payload.email)
        .pipe(
          map(() => {
            return { type: resetComplete.type }
          }),
          catchError(() => of({ type: resetFailed.type, error: 'Reset request failed' }))
        ))
    )
  });

  resetComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetComplete),
      tap(() => {
        this.snackbar.openFromComponent(SnackbarComponent, {
          data: {
            message: $localize`Reset link successfully sent to your email.`,
            status: 'success',
            showIcon: true
          },
          duration: 2500,
          horizontalPosition: 'end',
          verticalPosition: 'top'
        });
        this.router.navigateByUrl("/auth/reset-password");
      })
    )
  }, {dispatch: false});

  resetFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetFailed),
      tap(() => {})
    )
  }, {dispatch: false});

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
  }
}
