import { createAction, props } from '@ngrx/store';
import { User } from '@app/data/models';

export const setUserProfile = createAction(
  '[User] Set User Profile',
  props<{ data: User }>()
)

export const updateUserProfile = createAction(
  '[User] Update User Profile',
  props<{ data: User }>()
)
