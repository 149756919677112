import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { tap, exhaustMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { AuthService } from '@app/data/services/auth.service';
import { fetchExternalLoginDetails, login, loginComplete, loginFailure, loginSuccess } from '../actions';
import { LoaderService } from 'core/services/loader.service';
import { ICredentials } from '@app/data/models/auth/credentials';
import { SignInApiResponse } from '@app/data/models/auth/sign-in-api-response';

@Injectable()
export class AuthLoginEffects {
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(login),
      tap(() => (this.loaderService.setLoading(true))),
      exhaustMap((payload: ICredentials & Action) => this.authService.login(payload)
        .pipe(
          map(authRes => ({type: loginComplete.type, payload: {
              response: authRes,
              returnUrl: payload.returnUrl
            }})),
          catchError(() => {
            this.loaderService.setLoading(false);
            return of({type: loginFailure.type, payload: { returnUrl: payload.returnUrl }});
          })
        ))
    );
  });

  loginComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginComplete),
      tap(() => (this.loaderService.setLoading(false))),
      map(({ payload }: { payload: { response: SignInApiResponse, returnUrl?: string } }) => {
        if (payload.response && payload.response.accessToken) {
          this.authService.setAuth(payload.response);
          return {type: loginSuccess.type, returnUrl: payload.returnUrl};
        }
        return {type: loginFailure.type, payload: { returnUrl: payload.returnUrl }};
      })
    );
  });

  loginRedirect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginSuccess),
      exhaustMap((payload) => {
        if (payload.returnUrl) {
          return this.router.navigate([payload.returnUrl])
        } else {
          return this.router.navigate([this.authService.authSuccessUrl]);
        }
      })
    );
  }, {dispatch: false});

  loginErrorRedirect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginFailure),
      tap((action) => {
        if (action.payload.returnUrl) {
          return this.router.navigate([action.payload.returnUrl])
        } else {
          return this.router.navigate([this.authService.authFailureUrl]).then();
        }
      })
    );
  }, {dispatch: false});

  fetchExternalLoginDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchExternalLoginDetails),
      exhaustMap((action) => this.authService.fetchExternalLoginDetails(action.payload.loginId)
        .pipe(
          tap(() => {
            this.loaderService.setLoading(false);
          }),
          map(res => ({
            type: loginComplete.type,
            payload: { response: res }
          }))
        ))
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService
  ) {
  }
}
