import { IdName, mapToIdName } from '@app/data/models/shared/id-name';
import { PriceApi } from '@app/data/models/shared/price';

export interface TourInfoApiResponse {
  categories: Record<number, string>;
  timeOfDays: Record<number, string>;
  cities: Record<number, string>;
  interests: Record<number, string>;
  difficulties: Record<number, string>;
  durations: Record<number, string>;
  maxPrice: PriceApi
}

export type TourGenericInfo = {
  categories: IdName[] | null;
  timeOfDays: IdName[] | null;
  cities: IdName[] | null;
  interests: IdName[] | null;
  difficulties: IdName[] | null;
  durations: IdName[] | null;
  maxPrice: PriceApi | null;
}

export function mapTourInfoApiToTourGenericInfo(info: Partial<TourInfoApiResponse>): Partial<TourGenericInfo> {
  return {
    categories: info?.categories ? Object.entries(info?.categories).map(mapToIdName) : null,
    timeOfDays: info?.timeOfDays ? Object.entries(info?.timeOfDays).map(mapToIdName) : null,
    cities: info?.cities ? Object.entries(info?.cities).map(mapToIdName) : null,
    interests: info?.interests ? Object.entries(info?.interests).map(mapToIdName) : null,
    difficulties: info?.difficulties ? Object.entries(info?.difficulties).map(mapToIdName) : null,
    durations: info?.durations ? Object.entries(info?.durations).map(mapToIdName) : null,
    maxPrice: info?.maxPrice || null,
  };
}
